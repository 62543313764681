<template>
  <div class="resize-flavor">
    <div class="l-col resize-flavor__config">
      <div v-for="opt in nav" :key="opt.k" class="resize-flavor__config">
        <base-radio v-model="flavor" :value="opt.k" theme="tile" class="resize-flavor__config-item">
          {{ opt.v }}
        </base-radio>
      </div>
    </div>
    <div v-if="flavor === '1'" class="l-col">
      <base-resize-flavor
        :disk="tariff.localDisk"
        :list="innerList"
        @ready="onReady"
        @change="onChange"
      />
    </div>
    <div v-if="flavor === '2'" class="l-col">
      <slider-stack-block
        v-for="item in backupFormData"
        :key="item.name"
        :changable="true"
        :config="item.config"
        :label="item.label"
        :variable="true"
        :old="baseValues[item.name]"
        :period="item.period"
        :period-key="item.periodKey"
        :cost="item.cost"
        :hint="item.hint"
        class="margin-top"
        :class="{ small: item.propwidth && item.propwidth === 'yes' }"
        @change="onChangeSlider(item, $event)"
      />
    </div>
    <!--    <page-block v-if="summaryCost !== '0.00'" class="section-base">-->
    <page-block v-if="showCostDetails" class="section-base">
      <base-collapser-resize
        :summary="{
          more: summaryCost > 0 ? $t('summaryUp') : $t('summaryDown'),
          end:
            summaryCost > 0
              ? '+ ' + this.$n(summaryCost * period, 'currency')
              : this.$n(summaryCost * period, 'currency'),
        }"
        class="order-summary__list"
        @change-period="onPeriodChange"
      >
        <ul class="order-summary__list section-base">
          <li class="order-summary__item">
            <div class="order-summary__label standart-title">
              <label>Наименование</label>
            </div>
            <div class="order-summary__old standart-text">ресурс</div>
            <div class="order-summary__price standart-text">
              {{ `Цена за ${periodLabel}` }}
            </div>
          </li>
          <li v-for="(item, i) in configToCost" :key="i" class="order-summary__item">
            <div class="order-summary__label standart-title">
              {{ $t(`summaryLabels.${Object.keys(configToCost).find(x => x === i)}`) }}
            </div>
            <div class="order-summary__old standart-text">
              {{ item.old === item.new ? item.old : `${item.old} -> ${item.new}` }}
            </div>
            <div class="order-summary__price standart-text">
              {{ $n(item.cost * (item.new - item.old) * period, 'currency') }}
            </div>
          </li>
        </ul>
      </base-collapser-resize>
    </page-block>

    <div v-if="isQuotaRam === 0 || isQuotaCores === 0" class="l-col">
      <label class="typo__label medium-title section-base error-color">{{
        $t('quotas.quota')
      }}</label>
      <div v-if="isQuotaCores === 0" class="resize-flavor__quotas-first">
        <label class="resize-flavor__quotas-label">{{ $t('quotas.cpulimit') }}</label>
      </div>
      <div v-if="isQuotaRam === 0" class="resize-flavor__quotas-last">
        <label class="resize-flavor__quotas-label">{{ $t('quotas.ramlimit') }}</label>
      </div>
    </div>
    <div v-if="tariff.status !== 'shutoff'" class="l-col">
      <label class="typo__label medium-title section-base error-color">{{ $t('text') }}</label>
    </div>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import BaseResizeFlavor from '@/components/BaseResizeFlavor/BaseResizeFlavor';
import SliderStackBlock from '@/components/Configurator/components/SliderStackBlock.vue';
import BaseRadio from '@/components/Checkboxes/BaseRadio.vue';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import BaseCollapserResize from '@/components/BaseCollapser/BaseCollapserResize';
export default {
  name: 'ResizeFlavor',
  components: { BaseResizeFlavor, BaseRadio, SliderStackBlock, BaseCollapserResize },
  props: {
    text: {
      type: String,
      default: '',
    },
    tariff: {
      type: OPTariff,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
    price: {
      type: Array,

      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      flavor: '1',
      period: 1,
      baseValues: {
        vcpus: this.value.vcpus,
        ram: this.value.ram / 1024,
        disk: this.value.disk,
      },
      temp: {},
      backupFormData: [],
      nav: [
        {
          v: this.$t('nav.base'),
          k: '1',
        },
        {
          v: this.$t('nav.other'),
          k: '2',
        },
      ],
    };
  },
  computed: {
    isQuotaCores() {
      if (
        this.$store.state.moduleStack.quotasServer &&
        this.$store.state.moduleStack.quotasServer.cores
      ) {
        return (
          this.$store.state.moduleStack.quotasServer.cores.limit -
          this.$store.state.moduleStack.quotasServer.cores.in_use
        );
      } else return 0;
    },
    isQuotaRam() {
      if (
        this.$store.state.moduleStack.quotasServer &&
        this.$store.state.moduleStack.quotasServer.ram
      ) {
        return (
          this.$store.state.moduleStack.quotasServer.ram.limit -
          this.$store.state.moduleStack.quotasServer.ram.in_use
        );
      } else return 0;
    },
    dev() {
      return IS_PROD ? 'stat' : 'full_price';
    },
    // quotaDisk() {
    //   return this.$store.getters['moduleStack/aviableSpaseVolume'];
    // },
    quotaRam() {
      return Math.floor(this.$store.getters['moduleStack/aviableRam'] / 1024);
    },
    quotaCpu() {
      return this.$store.getters['moduleStack/aviableCpu'];
    },
    innerList() {
      const titleStart = this.title ? this.title : this.$t('title');
      return [
        {
          title: `${titleStart} \n ${
            this.list.find(x => x.name === this.value.name) ? this.value.name : this.$t('nav.other')
          }`,
          list: this.tariff.localDisk
            ? this.list
                .filter(x => x.disk > this.value.disk)
                .filter(x => x.ram / 1024 <= this.ram)
                .filter(x => x.vcpus <= this.vcpus)
            : this.list
                .filter(x => x.disk === 0)
                .filter(x => x.ram / 1024 <= this.ram)
                .filter(x => x.vcpus <= this.vcpus),
          value: this.value,
          id: this.tariff.id,
        },
      ];
    },
    periodLabel() {
      return this.priceByPeriod.find(i => i.value === this.period).label.toLowerCase();
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === +this.period);
    },
    priceByPeriod() {
      return [
        {
          label: this.$t('hour'),
          value: 1,
        },
        {
          label: this.$t('day'),
          value: 24,
        },
        {
          label: this.$t('month'),
          value: 24 * 30,
        },
      ];
    },
    disk() {
      // return this.$store.getters['moduleStack/aviableSpaseVolume'];
      return 1024;
    },
    ram() {
      return Math.floor(this.$store.getters['moduleStack/aviableRam'] / 1024);
    },
    vcpus() {
      return this.$store.getters['moduleStack/aviableCpu'];
    },
    summaryCost() {
      let sum = 0;
      Object.values(this.configToCost).forEach(x => {
        sum += (x.new - x.old) * x.cost;
      });
      return sum.toFixed(2);
    },
    config() {
      const config = [
        {
          name: 'vcpus',
          cost:
            this.price && this.price.length
              ? this.price.find(x => x.intname === 'vcpus')[this.dev]
              : null,
          period: '-1000',
          measure: 'МБ',
          intname: 'disc',
          label: 'CPU',
          type: 'slider',
          periodKey: 'month',
          config: {
            value: this.baseValues.vcpus,
            min: 1,
            max: 24,
            step: 1,
            limit: 1,
            measure: 'шт.',
            restrictclientchange: 'off',
            disabled: false,
          },
        },
        {
          name: 'ram',
          cost:
            this.price && this.price.length
              ? this.price.find(x => x.intname === 'memory')[this.dev]
              : null,
          period: '-1000',
          measure: 'ГБ',
          intname: 'disc',
          label: 'Оперативная память',
          type: 'slider',
          periodKey: 'month',
          config: {
            value: this.baseValues.ram < 1 ? 1 : this.baseValues.ram,
            min: 1,
            max:
              96 < this.ram + this.baseValues.ram ? 96 : Math.floor(this.ram + this.baseValues.ram),
            step: 1,
            limit: 1,
            measure: 'ГБ',
            restrictclientchange: 'off',
            disabled: false,
          },
        },
      ];
      const disk = {
        name: 'disk',
        cost:
          this.price && this.price.length
            ? this.price.find(x => x.intname === 'root_gb')[this.dev]
            : null,
        period: '-1000',
        measure: 'ГБ',
        intname: 'disc',
        // setvalues: 'final',
        label: 'Локальный диск',
        // hint: 'Значение дополнения с учетом включенного в тариф лимита:<br/> - включено в тариф: <b>6144 МБ</b><br/> - заказано дополнительно: <b>0 МБ</b>   <br/> - максимум: <b>4096000 МБ</b>',
        type: 'slider',
        // desc: 'ГБ (0.00 RUB за месяц за 1024 МБ)',
        periodKey: 'month',
        config: {
          value: this.baseValues.disk,
          min: this.baseValues.disk,
          max: this.disk,
          step: 1,
          limit: 1,
          measure: 'ГБ',
          restrictclientchange: 'off',
          disabled: false,
        },
      };
      if (this.tariff.localDisk) config.push(disk);
      return config;
    },
    configToCost() {
      if (this.flavor === '2') {
        const summaryCost = {
          vcpus: {
            old: this.value.vcpus,
            new: Object.keys(this.backupFormData).length
              ? this.backupFormData[0].config.value
              : this.value.vcpus,
            cost: Object.keys(this.backupFormData).length ? this.backupFormData[0].cost : 0,
          },
          ram: {
            old: this.value.ram / 1024,
            new: Object.keys(this.backupFormData).length
              ? this.backupFormData[1].config.value
              : this.value.ram / 1024,
            cost: Object.keys(this.backupFormData).length ? this.backupFormData[1].cost : 0,
          },
        };
        if (
          this.value.disk &&
          Object.keys(this.backupFormData).length > 0 &&
          Object.keys(this.backupFormData[2]).length > 0
        ) {
          summaryCost.disk = {
            old: this.value.disk,
            new: Object.keys(this.backupFormData).length
              ? this.backupFormData[2].config.value
              : this.value.disk,
            cost: Object.keys(this.backupFormData).length ? this.backupFormData[2].cost : 0,
          };
        }
        return summaryCost;
      } else {
        const summaryCost = {
          vcpus: {
            old: this.value.vcpus,
            new: Object.keys(this.temp).length ? this.temp.vcpus : this.value.vcpus,
            cost: Object.keys(this.backupFormData).length ? this.backupFormData[0].cost : 0,
          },
          ram: {
            old: this.value.ram / 1024,
            new: Object.keys(this.temp).length ? this.temp.ram / 1024 : this.value.ram / 1024,
            cost: Object.keys(this.backupFormData).length ? this.backupFormData[1].cost : 0,
          },
        };
        if (this.value.disk && Object.keys(this.temp).length > 0 && this.temp.disk > 0) {
          summaryCost.disk = {
            old: this.value.disk,
            new: Object.keys(this.backupFormData).length ? this.temp.disk : this.value.disk,
            cost: Object.keys(this.backupFormData).length ? this.backupFormData[2].cost : 0,
          };
        }
        return summaryCost;
      }
    },
    showCostDetails() {
      return (
        this.summaryCost !== '0.00' ||
        this.configToCost.vcpus.new !== this.configToCost.vcpus.old ||
        this.configToCost.ram.new !== this.configToCost.ram.old
      );
    },
  },
  watch: {
    flavor(val) {
      this.period = 1;
      if (val === '1') {
        this.backupFormData[0].config.value = this.value.vcpus;
        this.backupFormData[1].config.value = this.value.ram / 1024;
        if (Object.keys(this.backupFormData).length === 3 && this.value.disk > 0)
          this.backupFormData[2].config.value = this.value.disk;
      } else {
        this.temp = Object.assign({}, this.value);
      }
    },
    backupFormData: {
      handler: function (newVal, oldVal) {
        // console.log('vcpus', newVal[0].config.value, oldVal[0]?.config.value);
        // console.log('ram', newVal[1].config.value, oldVal[1]?.config.value);
        if (newVal[0].config.value > 8) {
          this.backupFormData[1].config.min = newVal[0].config.value * 2;
          this.backupFormData[1].config.value =
            this.backupFormData[1].config.value < this.backupFormData[1].config.min
              ? this.backupFormData[1].config.min
              : this.backupFormData[1].config.value;
        } else if (newVal[1].config.value) {
          this.backupFormData[1].config.value = newVal[1].config.value;
          this.backupFormData[1].config.min = 1;
          // this.backupFormData[1].config.value = this.value.ram / 1024;
        } else {
          this.backupFormData[1].config.min = 1;
          this.backupFormData[1].config.value = this.value.ram / 1024;
        }
        // console.log('disk', newVal[2].config.value, oldVal[2]?.config.value);
        // console.log('-------------------------------------------------------');
        const params = {
          ram: this.configToCost.ram.new,
          vcpus: this.configToCost.vcpus.new,
        };
        this.config.find(x => x.name === 'disk')
          ? (params.disk = this.config.find(x => x.name === 'disk').config.value)
          : null;
        this.$emit('create', params);
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$emit('notready');
      if (!this.price) this.getStackPrice();
      this.backupFormData = Object.assign({}, this.config);
    },
    onPeriodChange(obj) {
      this.period = obj;
    },
    getStackPrice() {
      return this.$store
        .dispatch('moduleStack/price', this.$store.getters['moduleStack/GET_CURRENT'])
        .then(() => {});
    },
    onReady() {
      this.$emit('notready');
    },
    onChangeSlider() {
      this.$emit('ready');
    },
    onChange(value) {
      this.$emit('ready');
      this.temp = value[this.tariff.id];
      this.$emit('change', value[this.tariff.id].id);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Текущая конфигурация:",
    "text": "Внимание! Изменение параметров потребует перезагрузку виртуального сервера",
    "summary": "Стоимость после изменения ресурсов ",
    "summaryUp": "Стоимость ресурсов увеличится на: ",
    "summaryDown": "Стоимость ресурсов уменьшится на:",
    "summaryLabels": {
      "disk": "Локальный диск, ГБ",
      "ram": "Оперативная память, ГБ",
      "vcpus": "Процессор, шт"
    },
    "quotas": {
      "quota": "Вы достигли лимита квоты по: ",
      "ramlimit": "- объему оперативной памяти",
      "cpulimit": "- количеству ядер"
    },
    "day": "день",
    "hour": "час",
    "month": "месяц",
    "nav": {
      "base" : "Базовые конфигурации",
      "other" : "Произвольная конфигурация"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize-flavor {

  &__config {
  display: flex;
  justify-content: space-around;

    &-item {
    }
}
  &__quotas {

    &-label {
      font-size: 16px;
      line-height: 1.25;
    }
    &-first {
      padding: 0.75rem 0 0.25rem;
    }
    &-last {
      padding: 0 0 0.5rem;
    }
  }

}
  .margin-top{
    margin-top: 1.75rem;
  }
.order-summary {
  &__list {
    width :100%
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem
    no-style-list();
  }
  &__item {
    +breakpoint(sm-and-up) {
      flexy(space-between, baseline);
    }
    & + & {
      //margin-top: 1.25rem;
      +breakpoint(sm-and-up) {
        margin-top: 0.25rem;
      }
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 33%;
      margin-right: 1.5rem;
    }
  }
  &__value {
    +breakpoint(sm-and-up) {
      flex: 0 0 33%;
      margin-right: 1.5rem;
    }
  }
  &__old {
    +breakpoint(sm-and-up) {
      flex: 0 0 33%;
      margin-right: 0.5rem;
    }
  }
  &__new {
    +breakpoint(sm-and-up) {
      flex: 0 0 33%;
      margin-right: 0.5rem;
    }
  }
  &__price {
    +breakpoint(sm-and-up) {
      flex: 0 0 33%;
      margin-right: 1.5rem;
    }
  }
}
</style>
